<template>
  <div>
    <div class="row">
      <div id="checkout" class="col-lg-8">
        <div class="row">
          <div id="bestellung" class="box col-lg-12 d-none d-md-block">
            <h3>
              Ihre Bestellung ({{ warenkorbanzahl }} Artikel)
              <router-link :to="{ name: 'Warenkorb' }"
                ><i class="fa fa-pencil"></i
              ></router-link>
            </h3>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="2">Produkt</th>
                    <th>Menge</th>
                    <th class="text-right">Einzelpreis</th>
                    <th class="text-right">inkl. MwSt</th>
                    <th colspan="1" class="text-right">Gesamt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="position in warenkorbpositionen"
                    :key="position.id"
                  >
                    <td>
                      <a href="#"
                        ><img
                          :src="getProduktListImageWithDefault(position.img)"
                          :alt="position.name"
                      /></a>
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'Produktdetail',
                          params: { warengruppe: position.warengruppe , artikelid: position.artikelid },
                        }"
                        >{{ position.name }}</router-link
                      >
                    </td>
                    <td class="text-center">
                      {{ position.menge }}
                    </td>
                    <td class="text-right">
                      {{ position.bruttopreis | betrag }} &euro;
                    </td>
                    <td class="text-right">
                      {{ position.umsatzsteuersatz }} %
                    </td>
                    <td class="text-right">
                      {{ position.gesamtpreis | betrag }} &euro;
                    </td>
                  </tr>
                </tbody>
                <!-- <tfoot>
                  <tr>
                    <th colspan="2" class="warenkorb-gesamtpreis">
                      Summe Artikel
                    </th>
                    <th colspan="4" class="text-right warenkorb-gesamtpreis">
                      {{ summe | betrag }} &euro;
                    </th>
                  </tr>
                </tfoot> -->
              </table>
            </div>
          </div>
          <div id="bestellung-small" class="box col-sm-12 d-block d-md-none">
            <h3>Ihre Bestellung<br />({{ warenkorbanzahl }} Artikel)
              <router-link :to="{ name: 'Warenkorb' }"
                ><i class="fa fa-pencil"></i
              ></router-link></h3>
               <!-- Warenkorb Small Size -->
      <div class="table-responsive d-block d-md-none">
        <table class="table">
          <thead>
            <tr>
              <th colspan="1">Produkt</th>
              <th></th>
              <th colspan="2" class="text-right">Gesamt</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="position in warenkorbpositionen" :key="position.id">
              <td>
                <a href="#"
                  ><img
                    :src="getProduktListImageWithDefault(position.img)"
                    :alt="position.name"
                /></a>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'Produktdetail',
                    params: { warengruppe: position.warengruppe , artikelid: position.artikelid },
                  }"
                  >{{ position.name }}</router-link><br />
                  <span>Einzelpreis&nbsp;</span><span class="text-nowrap">{{ position.bruttopreis | betrag }} &euro;</span><br />
                  <span>{{ position.menge }}&nbsp;St&uuml;ck</span><br />
              </td>
              <td class="text-right" colspan="2">
                <span class="text-nowrap">{{ position.gesamtpreis | betrag }} &euro;</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2" class="warenkorb-gesamtpreis">
                Summe Artikel
              </th>
              <th colspan="2" class="text-right warenkorb-gesamtpreis">
                <span class="text-nowrap">{{ summe | betrag }} &euro;</span>
              </th>
              <th class="warenkorb-gesamtpreis"></th>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- Ende Warenkorb Small Size -->
          </div>
        </div>
        <div class="row">
          <div class="box col-lg-12">
            <div class="row">
              <div class="col-sm-6">
                <h4 class="checkoutheader">
                  Rechnungsadresse
                  <router-link :to="{ name: 'BestellungAdresse' }"
                    ><i class="fa fa-pencil"></i
                  ></router-link>
                </h4>
                <p>
                  {{ rechnunganrede }} {{ rechnungname }}<br />{{
                    rechnungstrasse
                  }}<br />{{ rechnungplzort }}<br />{{ rechnungland }}<br />{{
                    rechnungtelefon
                  }}<br />{{ rechnungemail }}
                </p>
              </div>
              <div class="col-sm-6">
                <h4 class="checkoutheader">
                  Lieferadresse
                  <router-link :to="{ name: 'BestellungAdresse' }"
                    ><i class="fa fa-pencil"></i
                  ></router-link>
                </h4>
                <p>
                  {{ lieferanrede }} {{ liefername }}<br />{{ lieferstrasse
                  }}<br />{{ lieferplzort }}<br />{{ lieferland }}<br />{{
                    liefertelefon
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <h4 class="checkoutheader">
                  Liefermethode
                  <router-link :to="{ name: 'BestellungLiefermethode' }"
                    ><i class="fa fa-pencil"></i
                  ></router-link>
                </h4>
                <p>
                  {{ versandart.titel }}<br />Mehr Informationen siehe hier: <router-link to="/about/versandinformationen">Versandkosten</router-link>
                </p>
              </div>
              <div class="col-sm-6">
                <h4 class="checkoutheader">
                  Zahlmethode
                  <router-link :to="{ name: 'BestellungZahlmethode' }"
                    ><i class="fa fa-pencil"></i
                  ></router-link>
                </h4>
                <p>{{ zahlungsart.titel }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box">
          <div class="text-center">
          <h3>Zusammenfassung</h3></div>

          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td>Summe Artikel</td>
                  <th class="text-right">{{ summe | betrag }} &euro;</th>
                </tr>
                <tr>
                  <td>Versandkosten</td>
                  <th class="text-right">
                    {{ versandkosten | betrag }} &euro;
                  </th>
                </tr>
                <tr v-for="paket in pakete" :key="paket.paketnummer">
                  <td class="paketzeile">Paket {{ paket.paketnummer }} ({{ paket.gewicht | kilogramm}} kg)</td>
                  <td class="text-right paketzeile">{{ paket.paketpreis | betrag }} &euro;</td>
                </tr>
                <tr><td class="paketzeile"></td><td class="paketzeile"></td></tr>
                <tr>
                  <td>Gesamtbetrag</td>
                  <th class="text-right">{{ gesamtpreis | betrag }} &euro;</th>
                </tr>
                <tr>
                  <td>enthält USt. 7%<br />enthält USt. 19%</td>
                  <th class="text-right">
                    {{ umsatzsteuer7 | betrag }} &euro;<br />{{
                      umsatzsteuer19 | betrag
                    }}
                    &euro;
                  </th>
                </tr>
                <tr>
                  <td></td>
                  <th></th>
                </tr>
              </tbody>
            </table>
          </div>
          <form class="needs-validation" novalidate>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="uebersicht.zustimmung"
                v-bind:class="{ 'is-invalid': !validierung.zustimmung.valid }"
                id="checkBedingungen"
              />
              <label class="form-check-label" for="checkBedingungen">
                Ich habe die
                <router-link to="/about/agb" target="_blank">AGB</router-link>,
                <router-link to="/about/widerruf" target="_blank"
                  >Widerrufsbestimmungen</router-link
                >
                und
                <router-link to="/about/datenschutz" target="_blank"
                  >Datenschutzbestimmungen</router-link
                >
                gelesen und akzeptiere diese.
              </label>
              <div class="invalid-feedback">
                Um die Bestellung aufgeben zu können, müssen Sie den Bedingungen
                zustimmen.
              </div>
            </div>

            <div class="text-center" style="margin-top: 10px">
              <button type="button" class="btn btn-primary d-none d-md-block" @click="bestellungAufgeben()">
                Bestellung aufgeben
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="box-footer d-flex justify-content-between flex-sm-row"
    >
      <div class="left">
        <router-link
          :to="{ name: 'BestellungZahlmethode' }"
          class="btn btn-outline-secondary"
          ><i class="fa fa-chevron-left"></i> Zur&uuml;ck</router-link
        >
      </div>
      <div class="right">
        <button type="button" class="btn btn-primary" @click="bestellungAufgeben()">
          Bestellung aufgeben
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { warenkorballgemein } from "@/mixins/warenkorb_allgemein.js";
import { standardfilters } from "@/mixins/standard_filters.js";
import {
  /*serviceLoadBestellung,*/
  serviceBestellungAufgeben,
} from "@/api/bestellung.api.js";
export default {
  name: "ShopCheckoutUebersicht",
  mixins: [warenkorballgemein, standardfilters],
  data: function () {
    return {
      uebersicht: {
        zustimmung: false,
      },
      validierung: { zustimmung: { valid: true, fb: "" } },
    };
  },
  computed: {
    pakete() {
      return this.$store.getters.getPakete;
    },
    warenkorbanzahl() {
      return this.$store.getters.getWarenkorbAnzahl;
    },
    warenkorbpositionen() {
      return this.$store.getters.getWarenkorbPositionen;
    },
    gesamtpreis() {
      return this.$store.getters.getWarenkorbGesamtpreis;
    },
    summe() {
      return this.$store.getters.getWarenkorbSumme;
    },
    versandkosten() {
      return this.$store.getters.getWarenkorbVersandkosten;
    },
    umsatzsteuer7() {
      return this.$store.getters.getWarenkorbUmsatzsteuer7;
    },
    umsatzsteuer19() {
      return this.$store.getters.getWarenkorbUmsatzsteuer19;
    },
    rechnungsadresse() {
      return this.$store.getters["bestellung/getAdressdaten"].rechnungsadresse;
    },
    lieferadresse() {
      return this.$store.getters["bestellung/getAdressdaten"].lieferadresse;
    },
    rechnungname() {
      return this.rechnungsadresse.vorname + " " + this.rechnungsadresse.name;
    },
    rechnunganrede() {
      return this.$store.getters["keyparameter/getAnrede"](
        this.rechnungsadresse.anrede
      ).ausgabetext;
    },
    rechnungstrasse() {
      return this.rechnungsadresse.strasse;
    },
    rechnungplzort() {
      return this.rechnungsadresse.plz + " " + this.rechnungsadresse.ort;
    },
    rechnungland() {
      return this.$store.getters["keyparameter/getEULand"](
        this.rechnungsadresse.land
      ).text;
    },
    rechnungtelefon() {
      return this.rechnungsadresse.telefon;
    },
    rechnungemail() {
      return this.rechnungsadresse.email;
    },
    liefername() {
      return this.lieferadresse.vorname + " " + this.lieferadresse.name;
    },
    lieferanrede() {
      return this.$store.getters["keyparameter/getAnrede"](
        this.lieferadresse.anrede
      ).ausgabetext;
    },
    lieferstrasse() {
      return this.lieferadresse.strasse;
    },
    lieferplzort() {
      return this.lieferadresse.plz + " " + this.lieferadresse.ort;
    },
    lieferland() {
      return this.$store.getters["keyparameter/getEULand"](
        this.lieferadresse.land
      ).text;
    },
    liefertelefon() {
      return this.lieferadresse.telefon;
    },
    versandart() {
      return this.$store.getters["keyparameter/getVersandart"](this.$store.getters["bestellung/getVersandartId"]);
    },
    zahlungsart() {
      return this.$store.getters["keyparameter/getZahlungsart"](this.$store.getters["bestellung/getZahlungsartId"]);
    },
  },
  mounted() {
    console.log("MOUNTED Bestellübersicht");
    this.$store.dispatch("providingHashvalues").then(async r => { 
      if (r) {
      // try {
        console.log("mounted after providing Hashvalues: call serviceLoadBestellung");
        console.log("warenkorbHash " + this.$store.getters.getWarenkorbHash);
        console.log("kundenHash " + this.$store.getters.getKundenHash);
      // const response = await serviceLoadBestellung(
      //   this.$store.getters.getWarenkorbHash,
      //   this.$store.getters.getKundenHash
      // );
      // if (response.data.successful) {
        // if (!this.$store.getters["bestellung/getVersandartId"]) {
        //   this.$store.commit("bestellung/setVersandartId", response.data.bestellung.versandartid);  
        //   this.$store.commit("bestellung/setZahlungsartId", response.data.bestellung.zahlungsartid);   
        // }
        this.$store.dispatch("reloadBestellungFallsErforderlich", this.$store.getters.getKundenHash).then( r => { 
          console.log("r => " + r);
          // Bei Versandkostenberechnung beachten: Lieferland und Versandart-Typ 
          this.$store.dispatch("loadWarenkorbMitVersandkosten", {
            "lieferland": this.lieferadresse.land, "versandarttyp": this.$store.getters["keyparameter/getVersandart"](this.$store.getters["bestellung/getVersandartId"]).typ,
          });
        })

      // }
    // } catch (error) {
    //   console.log("ShopCheckoutUebersicht mounted ERROR: " + error);
    // }
    }})

  },
  methods: {
    async bestellungAufgeben() {
      try {
        const response = await serviceBestellungAufgeben(
          this.uebersicht.zustimmung,
          this.$store.getters.getWarenkorbHash,
          this.$store.getters.getKundenHash
        );
        this.validierung.zustimmung = response.data.validierung.zustimmung;
        if (response.data.successful) {
          this.$store.dispatch("resetBestellungAbgeschlossen");
          this.$router.push({ name: "BestellungAbgeschlossen" });
        }
      } catch (error) {
        console.log(
          "ShopCheckoutUebersicht bestellungAufgeben ERROR: " + error
        );
      }
    },
  },
};
</script>
<style scoped>
.nav-link {
  padding: 0;
}
.clickicon {
  cursor: pointer;
}
.warenkorb-summe {
  padding: 3px 12px;
}
.warenkorb-versandkosten {
  border-top: 0px solid white;
  padding: 3px 12px;
}
.warenkorb-gesamtpreis {
  border-top: 1px solid #dee2e6;
  border-bottom: 5px double #dee2e6;
  padding: 3px 12px;
  font-size: 0.9rem;
}
tfoot {
  font-size: 1rem;
}
.checkoutheader {
  font-weight: 700;
}
table {
  margin-bottom: 0;
}

.paketzeile {
  border-top: 0;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  font-size: 0.75rem;
}
</style>
